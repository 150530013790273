<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="false" />
                <h2 class="page-title">&nbsp;</h2>
            </div>
        </div>
        <!-- page-body -->
        <div v-if="isReady" class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="board-view-container">
                    <header class="board-header">
                        <h3 class="board-title">{{ params.boardNm }}</h3>
                        <div class="board-meta">{{ timestampToDateFormat(params.createDt, 'yyyy.MM.dd hh:mm') }}</div>
                    </header>
                    <div class="board-body swcc-editor-container" v-html="params.boardContents"></div>
                    <BoardAttachment :attachments="attachments" />
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div v-if="session.name !== null && session.manager" class="page-bottom" style="bottom: 48px">
            <div class="bottom-board-upload bottom-component">
                <button class="btn btn-primary" @click="goEdit('Notice', 'bno', bno)"><span class="text">글수정 </span></button>
            </div>
        </div>

        <div v-if="isReady" class="page-bottom">
            <div class="board-nav bottom-component">
                <nav class="board-nav-wrapper">
                    <router-link v-if="moveBno.prev > 0" :to="{params: {bno: moveBno.prev}}" class="nav-prev"><i class="icon-prev"></i> 이전</router-link>
                    <a v-else href="javascript:" class="nav-prev"></a>

                    <router-link :to="{name: 'Notice'}" class="nav-list">목록</router-link>
                    <router-link v-if="moveBno.next > 0" :to="{params: {bno: moveBno.next}}" class="nav-next">다음 <i class="icon-next"></i></router-link>
                    <a v-else href="javascript:" class="nav-next"></a>
                </nav>
            </div>
        </div>
        <!-- //page-bottom -->
    </main>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {collectionCheck, getItem, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/common.utils';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import ApiService from '@/assets/js/api.service';
import {getAuthSession} from '@/assets/js/modules/auth/module';
import {goEdit} from '@/assets/js/modules/common/module';
import BoardAttachment from '@/components/board/BoardAttachment';

export default {
    name: 'NoticeView',
    components: {BoardAttachment, CommonBreadcrumb},
    setup() {
        const isReady = ref(false);
        const session = computed(getAuthSession);
        const route = useRoute();

        const bno = computed(() => (route.params.bno ? parseInt(route.params.bno) : 0));

        const params = reactive({
            bno: 0,
            boardNm: '',
            boardContents: '',
            boardTypeDcd: '',
            createDt: '',
        });
        const attachments = ref([]);

        const moveBno = reactive({
            prev: 0,
            next: 0,
        });

        const getBoardNotice = () => {
            if (bno.value > 0) {
                ApiService.get('/v1/boards', bno.value).then(res => {
                    if (lengthCheck(res)) {
                        const item = getItem(res);
                        setParams(params, item);
                        moveBno.prev = item.prevBno || 0;
                        moveBno.next = item.nextBno || 0;

                        if (collectionCheck(item.boardFiles, 'fileNo')) {
                            attachments.value.push(...item.boardFiles);
                        }
                    }
                    isReady.value = true;
                });
            }
        };

        getBoardNotice();

        watch(
            () => route.params.bno,
            () => {
                if (route.name === 'NoticeView') {
                    isReady.value = false;
                    getBoardNotice();
                }
            },
        );

        return {
            bno,
            isReady,
            session,
            params,
            attachments,
            moveBno,
            timestampToDateFormat,
            goEdit,
        };
    },
};
</script>
